/* Adjustments for phone screens */
/* Adjustments for phone screens */
/* Adjustments for phone screens */
/* Adjustments for phone screens */
/* Adjustments for phone screens */
@media screen and (max-width: 768px) {
  .main {
    width: 90%; /* Reduce width for better fit */
    max-width: none; /* Remove max-width to allow full width on smaller screens */
    margin: 50px auto; /* Adjust margin for better positioning */
    position: relative;
    padding: 10px 15px 70px; /* Adjust padding for better spacing */
    overflow: auto; /* Add both horizontal and vertical scrolling when content overflows */
    white-space: nowrap; /* Prevent content from wrapping */
    height: auto; /* Allow the container to expand vertically */
  }

  /* Limit the width of the box container */
  .box {
    display: none; /* Hide animation box on phones */
  }

  /* Force the caption to wrap onto multiple lines */
  .caption {
    white-space: normal; /* Allow text to wrap */
    word-wrap: break-word; /* Break words to fit into multiple lines */
    max-width: 100%; /* Ensure caption doesn't exceed container width */
  }

  /* Adjustments to footer */
  footer {
    margin-top: 20px; /* Increase margin to avoid overlap */
    padding: 10px 0; /* Maintain padding */
    text-align: center; /* Center align text */
    position: relative; /* Ensure footer stays at the bottom */
    width: 100%; /* Full width */
  }
}


/* Original CSS */
body {
  margin: 0;
  padding: 0;
  position: relative; /* Ensure the body is positioned relative */
  min-height: 100vh;
}
.app {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: linear-gradient(90deg, rgba(2, 0, 0.6, 1) 0%, rgba(9, 9, 121, 1) 35%, rgba(0, 212, 255, 1) 100%);
  /* padding-bottom: 50px; */
}
.box div {
  position: absolute;
  width: 60px;
  height: 60px;
  background-color: transparent;
  border: 6px solid rgba(255, 255, 255, 0.8);
}
.box div:nth-child(1) {
  top: 12%;
  left: 42%;
  animation: animate 10s linear infinite;
}
.box div:nth-child(2) {
  top: 70%;
  left: 50%;
  animation: animate 7s linear infinite;
}
.box div:nth-child(3) {
  top: 17%;
  left: 6%;
  animation: animate 9s linear infinite;
}
.box div:nth-child(4) {
  top: 20%;
  left: 60%;
  animation: animate 10s linear infinite;
}
.box div:nth-child(5) {
  top: 67%;
  left: 10%;
  animation: animate 6s linear infinite;
}
.box div:nth-child(6) {
  top: 80%;
  left: 70%;
  animation: animate 12s linear infinite;
}
.box div:nth-child(7) {
  top: 60%;
  left: 80%;
  animation: animate 15s linear infinite;
}
.box div:nth-child(8) {
  top: 32%;
  left: 25%;
  animation: animate 15s linear infinite;
}
.box div:nth-child(9) {
  top: 12%;
  left: 42%;
  animation: animate 10s linear infinite;
}
.box div:nth-child(10) {
  top: 20%;
  left: 80%;
  animation: animate 5s linear infinite;
}
@keyframes animate {
  0% {
    transform: scale(0) translateY(0) rotate(0);
  }
  100% {
    transform: scale(1.3) translateY(-90px) rotate(360deg);
    opacity: 0;
  }
}
.main {
  width: 100%;
  max-width: 540px;
  background: white;
  margin: 50px auto 20px;
  padding: 10px 30px 70px;
  border-radius: 10px;
  padding: 10px 30px 70px;
  position: relative;
}
.main h1 {
  color: #002765;
  text-align: center;
  margin-bottom: 20px;
  font-size: 50px;
}
.main p {
  text-align: center;
  font-family: "Adelle Sans Devanagari";
}
.input-container {
  align-items: center;
  display: flex;
  justify-content: space-between;
  background: #edeef0;
  border-radius: 20px;
  padding-left: 20px;
  margin-bottom: 25px;
  margin-top: 30px;
}
input {
  flex: 1;
  border: none;
  outline: none;
  background: transparent;
  padding: 10px;
}
.input-container button {
  border: none;
  outline: none;
  padding: 16px 20px;
  background: rgba(9, 9, 121, 1);
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  border-radius: 20px;
}
.caption {
  font-family: "Geeza Pro";
  font-style: italic;
  font-size: 20px;
  margin-bottom: 16px;
  padding: 16px 0px 0px 20px;
}
.output-container {
  background-color: #b5c8ef;
  border-radius: 15px;
  display: inline-flex;
  -webkit-align-items: flex-end;
}
.output-container button {
  font-size: small;
  border-radius: 5px;
  padding: 0px 5px;
  border: none;
  outline: none;
  background: rgba(9, 9, 121, 1);
  color: #fff;
  cursor: pointer;
  height: 30px;
}
footer {
  text-align: center;
  position: absolute;
  bottom: 0; /* Ensure the footer stays at the bottom */
  width: 100%;
  margin-top: 20px;
  padding: 10px 0;
  color: white;
  /* background-color: #f2f2f2; */
}
